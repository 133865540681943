import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';

// Servicios
import { StorageService } from './storage.service';

// Models
import { InformacionUsuario } from '../sesionStore/info-usuario.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class authService {

  // private subscriptionSesion: Subscription;
  infoSesion = new BehaviorSubject<any>(false);

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private storageService: StorageService
  ) { }

  inicioSesion(_datosUsuario: FormData) {

    return this.http.post<InformacionUsuario>(environment.url + 'Controlador_login/iniciarSesion', _datosUsuario).
      pipe(
        retry(3),
        tap(
          respuesta => {
            this.guardaLocalStore(respuesta);
          }
        )
      )
  }


  guardaLocalStore(_infoUsuario: any) {

    if (_infoUsuario) {
      this.infoSesion.next(_infoUsuario);
      this.storageService.secureStorage.setItem('usuario', _infoUsuario);
    }

  }

  // * Se crea el autologin para el usuario (Para poder acceder a su informacion)
  recuperarInforUsuarioStore() {
    const infoUsuario: any = this.storageService.secureStorage.getItem('usuario');

    if (!infoUsuario) {
      return;
    }

    this.infoSesion.next(infoUsuario);
  }


  cerrarSesion() {
    this.storageService.secureStorage.clear();
    this.infoSesion.next(false);
  }

  obtenerDatosSesion() {
    return this.infoSesion.asObservable();
  }

}
