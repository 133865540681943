<button class="btn estilos-pestaña focus-elemento"
        [ngClass]="{'estilos-pestaña': !mostrarProcesos, 'estilos-pestaña-abierta': mostrarProcesos}"
        mat-button
        *ngIf="progreso.length > 0"
        (click)="mostrarProcesos = !mostrarProcesos">

    <div class="row align-items-center my-2 form-group">

        <div class="col-12 d-flex align-items-center pr-0">

            <div class="spinner-border-custom texto-azul ml-md-2"
                 style="pointer-events: none;"
                 role="status">
            </div>

            <img class="img-upload"
                 style="pointer-events: none;"
                 src="assets/img/registro-cursos/materiales-adjuntos/upload.png"
                 alt="img upload">

            <span class="h5 text-right pl-3"
                  style="pointer-events: none;"
                  *ngIf="mostrarProcesos">Guardando...</span>

            <img class="ml-auto"
                 style="width: 2rem; height: auto; pointer-events: none;"
                 src="assets/img/registro-cursos/materiales-adjuntos/arrow-right.png"
                 alt="img">
        </div>

    </div>
</button>

<div class="container estilos-contenedor ml-2 ml-md-3 mt-3"
     style="z-index: 3000;"
     *ngIf="mostrarProcesos">

    <div class="row">
        <div class="col-12 text-right">
            <fa-icon class="texto-rojo"
                     (click)="mostrarProcesos = false"
                     style="cursor: pointer;"
                     [icon]="['fas', 'times']"></fa-icon>
        </div>
    </div>


    <div class="row mb-3 align-items-center"
         *ngFor="let elementoSubiendo of progreso">

        <div class="col-4"
             style="overflow: hidden; white-space: nowrap;"
             matTooltip="{{elementoSubiendo.nombre}}">
            <span>{{elementoSubiendo.nombre}}</span>
        </div>
        <div class="col-6">

            <mat-progress-bar *ngIf="elementoSubiendo.progreso === 100"
                              class="w-100"
                              mode="indeterminate"></mat-progress-bar>
            <mat-progress-bar *ngIf="elementoSubiendo.progreso < 100"
                              class="w-100"
                              mode="determinate"
                              value="{{elementoSubiendo.progreso}}"></mat-progress-bar>
            <mat-progress-bar *ngIf="elementoSubiendo.progreso === 101"
                              class="w-100 green-progress"
                              mode="determinate"
                              value="100"></mat-progress-bar>

        </div>

        <div class="col-2 pl-0">
            <span>{{mostrarAvanceSubida(elementoSubiendo.progreso)}}</span>
        </div>
    </div>

    <div class="row">
        <div class="col-12 text-center">
            <span><b>¡No cierre ni actualice el navegador hasta que finalicen los procesos!</b></span>
        </div>
    </div>

</div>