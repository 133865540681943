import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { authService } from 'src/app/core/auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2';
import * as acciones from 'src/app/core/sesionStore/sesion.action';
import { Store } from '@ngrx/store';

// * Servicios
import { ComunicacionModalesService } from 'src/app/plataformas/online/services/comunicacion-modales.service';
import { LandingService } from 'src/app/plataformas/online/services/landing.service';
import { NotificacionesService } from 'src/app/plataformas/online/services/notificaciones.service';
import { Subscription } from 'rxjs';
import { Modales } from '../services/modales.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subscriptionAgregadoAlCarrito: Subscription;
  private subscriptionEliminadoDelCarrito: Subscription;
  private subscriptionnumeroDeNotificaciones: Subscription;

  @ViewChild('botonBuscar', { static: false }) botonBuscar: ElementRef;


  // * Variables de Usuario
  tipoRol: string;
  nombreRol = false;

  idUsuario: string;
  nombreUsuario: string;
  apellidosUsuario: string;
  sesionIniciada = false;

  // * Variables de tipo de rol
  mostrarCursos = false;
  iniciarCerrarSesion = false;

  // Variable ue guardara las categorias
  categorias: any;

  // Funcion mandar componente
  @Output() abrirSidebar = new EventEmitter();

  mostrarTexto = false;

  // * Variable para badge de notificaciones
  numeroNotificaciones = 0;

  // * Variable para badge de elementos en el carrito
  numeroElementosCarrito = 0;

  // Indica si es administrador / Colaborador

  esAdminColaborador = false;

  nombreDeRol: string;

  constructor(
    private autService: authService,
    private landing: LandingService,
    private router: Router,
    private servicioModales: Modales,
    private store: Store,
    private servicioComunicacionModales: ComunicacionModalesService,
    private servicioNotificaciones: NotificacionesService
  ) {

  }

  ngOnInit() {
    Swal.close();
    // * Obtenemos los datos del usuario en sesion
    this.autService.obtenerDatosSesion().subscribe(res => {

      if (res) {
        this.sesionIniciada = true;
        this.iniciarCerrarSesion = true;

      }


      this.tipoRol = res.tipoRol;
      if (this.tipoRol !== '1' && this.tipoRol !== '8') {
        this.mostrarCursos = true;
      }


      this.idUsuario = res.id_Usuario;
      this.nombreUsuario = res.nombre;
      this.apellidosUsuario = res.apellidos;

      if (res.tipoRol == '6') {

        this.nombreDeRol = 'Administrador';
        this.nombreRol = true;
        this.esAdminColaborador = true;


      }

      if (res.tipoRol == '7') {

        this.nombreDeRol = 'Colaborador';
        this.nombreRol = true;
        this.esAdminColaborador = true;

      }

      this.router.events.subscribe((val) => {
        // see also
        if (val instanceof NavigationEnd) {

          if (res.idUsuario) {
            this.servicioNotificaciones.obtenerNumeroNotificaciones(res.idUsuario).toPromise()
              .then((resp: number) => {

                if (resp) {
                  this.numeroNotificaciones = resp;
                } else {
                  this.numeroNotificaciones = 0;
                }
              })
              .catch((error) => {
                this.numeroNotificaciones = 0;
              });

            this.servicioNotificaciones.obtenerNumeroElementosCarrito(res.idUsuario).toPromise()
              .then((resp: number) => {

                if (resp) {
                  this.numeroElementosCarrito = resp;
                } else {
                  this.numeroElementosCarrito = 0;
                }
              })
              .catch((error) => {
                this.numeroElementosCarrito = 0;
              });
          }
        }

      });

    });

    this.elementoAgregadoAlCarrito();
    this.elementoEliminadoDelCarrito();
    this.numeroDeNotificaciones();
  }

  ngOnDestroy() {
    if (this.subscriptionAgregadoAlCarrito) {
      this.subscriptionAgregadoAlCarrito.unsubscribe();
    }

    if (this.subscriptionEliminadoDelCarrito) {
      this.subscriptionEliminadoDelCarrito.unsubscribe();
    }

    if (this.subscriptionnumeroDeNotificaciones) {
      this.subscriptionnumeroDeNotificaciones.unsubscribe();
    }
  }

  abrirMenu() {
    if (this.mostrarTexto) {
      this.mostrarTexto = false;
      this.abrirSidebar.emit(false);
    } else {
      this.mostrarTexto = true;
      this.abrirSidebar.emit(true);
    }
  }

  // Esta funcion solo simula el click del boton de buscar para cerrar el menu
  buscarEnter() {
    this.botonBuscar.nativeElement.click();
  }

  cerrarSesion() {
    this.servicioModales.lanzarModalConfirmar(
      "¿Estás seguro que quieres cerrar sesión?",
      'Selecciona "Confirmar" para finalizar tu sesión actual.',
      () => {
        this.autService.cerrarSesion();
        this.iniciarCerrarSesion = false;
        this.mostrarCursos = false;
        window.location.replace('/');
      }
    );
  }

  // Funcion que obtiene las categorias
  obtenerCategorias() {
    this.landing.obtenerCategorias().subscribe(datos => {
      this.categorias = datos;
    });
  }

  /* Funcion que recibe termino de busuqueda y tipo de busqueda (busqueda de cursos/busqueda de categorias)
    Si el tipo de busqueda es igual a 1, es una busqueda por curso
    Si el tipo es igual a dos, es una busqueda por categoria*/
  relizarBusquedaCategoria(_terminoBusqueda, _tipoDeBusqueda) {
    if (_terminoBusqueda && _tipoDeBusqueda) {
      this.router.navigate(['/listado-cursos', _tipoDeBusqueda, _terminoBusqueda]);
    }
  }

  // Modal inicio de sesion
  modalLogin() {
    this.servicioComunicacionModales.loginActivado$.next(true);
  }

  // Modal Registro
  modalRegistro() {
    this.servicioComunicacionModales.registroActivado$.next(true);
  }

  // * OBSERVABLES -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

  // * Elemento agregado al carrito
  obtenerObservadorElementoAgregadoAlCarritoActivado() {
    return this.servicioComunicacionModales.elementoAgregadoAlCarrito$.asObservable();
  }
  // * Elemento eliminado del carrito
  obtenerObservadorElementoEliminadoDelCarritoActivado() {
    return this.servicioComunicacionModales.elementoEliminadoDelCarrito$.asObservable();
  }
  // * Numero de notificaciones
  obtenerObservadorNumeroDeNotificaciones() {
    return this.servicioComunicacionModales.numeroDeNotificaciones$.asObservable();
  }

  elementoAgregadoAlCarrito() {
    this.subscriptionAgregadoAlCarrito = this.obtenerObservadorElementoAgregadoAlCarritoActivado().subscribe(
      (idUsuario) => {
        if (idUsuario) {

          this.servicioNotificaciones.obtenerNumeroElementosCarrito(idUsuario).toPromise()
            .then((respNumeroElementosCarrito: number) => {

              if (respNumeroElementosCarrito) {
                this.numeroElementosCarrito = respNumeroElementosCarrito;
              } else {
                this.numeroElementosCarrito = 0;
              }
            })
            .catch((error) => {
              this.numeroElementosCarrito = 0;
            });
        }
      }
    );
  }

  elementoEliminadoDelCarrito() {
    this.subscriptionEliminadoDelCarrito = this.obtenerObservadorElementoEliminadoDelCarritoActivado().subscribe(
      (idUsuario) => {
        if (idUsuario) {

          this.servicioNotificaciones.obtenerNumeroElementosCarrito(idUsuario).toPromise()
            .then((respNumeroElementosCarrito: number) => {

              if (respNumeroElementosCarrito) {
                this.numeroElementosCarrito = respNumeroElementosCarrito;
              } else {
                this.numeroElementosCarrito = 0;
              }
            })
            .catch((error) => {
              this.numeroElementosCarrito = 0;
            });
        }
      }
    );
  }

  numeroDeNotificaciones() {
    this.subscriptionEliminadoDelCarrito = this.obtenerObservadorNumeroDeNotificaciones().subscribe(
      (idUsuario) => {
        if (idUsuario) {

          this.servicioNotificaciones.obtenerNumeroNotificaciones(idUsuario).toPromise()
            .then((resp: number) => {

              if (resp) {
                this.numeroNotificaciones = resp;
              } else {
                this.numeroNotificaciones = 0;
              }
            })
            .catch((error) => {
              this.numeroNotificaciones = 0;
            });
        }
      }
    );
  }
  // * END OBSERVABLES -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*


}
