import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Importar Librerias fontAwesome
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab, faAngular } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// Importaciones interna de angular
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

// Angular materials
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule, MatBadgeModule, MatIconModule } from '@angular/material';
import { MatNativeDateModule } from '@angular/material/';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';

// * Importacion de Datatable
import { DataTablesModule } from 'angular-datatables';

// * Importación de SweetAlert para Angular
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// Importaciones editor de texto
import { QuillModule } from 'ngx-quill';
import { MatSelectModule } from '@angular/material/select';

// Importaciones video Angular
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { InterceptorHttpService } from './auth/interceptor-http.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    SharedModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatBadgeModule,
    MatIconModule,
    MatNativeDateModule,
    DataTablesModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    DragDropModule,
    MatCardModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    SweetAlert2Module.forRoot(),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],        // toggled buttons
          // ['blockquote', 'code-block'],

          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript

          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

          [{ 'align': [] }],

          ['clean'],                                         // remove formatting button
        ]
      }
    })
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    SharedModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatBadgeModule,
    MatIconModule,
    MatNativeDateModule,
    DataTablesModule,
    SweetAlert2Module,
    QuillModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    DragDropModule,
    MatCardModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatChipsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorHttpService,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(_library: FaIconLibrary) {
    _library.addIconPacks(fas, fab, far);
  }
}
