import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificacionesService {

    constructor(
        private httpClient: HttpClient
    ) { }

    obtenerNumeroNotificaciones(_idUsuario: string) {
        return this.httpClient.post(
            environment.url + 'Controlador_notificaciones/obtenerNumeroNotificaciones',
            {
                idUsuario: _idUsuario
            }
        ).pipe(retry(3));
    }

    obtenerNumeroElementosCarrito(_idUsuario: string) {
        return this.httpClient.post(
            environment.url + 'Controlador_notificaciones/obtenerNumeroElementosCarrito',
            {
                idUsuario: _idUsuario
            }
        ).pipe(retry(3));
    }

    obtenerNotificaciones(_idUsuario: string) {
        return this.httpClient.post(
            environment.url + 'Controlador_notificaciones/obtenerNotificaciones',
            {
                idUsuario: _idUsuario
            }
        ).pipe(retry(3));
    }

    marcarNotificacionLeida(_idNotificacion: string) {
        return this.httpClient.post(
            environment.url + 'Controlador_notificaciones/marcarNotificacionLeida',
            {
                idNotificacion: _idNotificacion
            }
        );
    }


    async eliminarNotificacion(idNotificacion: string): Promise<boolean> {
        try {
            return await this.httpClient.post<boolean>(
                environment.url + 'Controlador_notificaciones/eliminarNotificacion',
                {
                    idNotificacion
                }
            ).toPromise();
        } catch (error) {
            console.log(error);
        }
    }

}