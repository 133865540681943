import { Injectable } from "@angular/core";
import { retry } from "rxjs/operators";
import { HttpEventType, HttpClient, HttpRequest, HttpProgressEvent, HttpEvent, HttpHeaders } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { Modales } from 'src/app/shared/services/modales.service';


@Injectable({
  providedIn: "root",
})
export class RegistrarCursoService {
  constructor(
    private http: HttpClient,
    private modalesService: Modales
  ) { }

  obtenerDatosEmpresa(_idUsuario: string) {
    return this.http.post(
      environment.url + 'Controlador_registro_curso/obtenerDatosEmpresa',
      {
        idUsuario: _idUsuario
      }
    ).pipe(retry(3));
  }

  obtenerDatosCurso(_idCurso: string, _idUsuario: string) { // EN USO
    return this.http
      .post<any>(
        environment.url + "Controlador_registro_curso/obtenerDatosCurso",
        { idCurso: _idCurso, idUsuario: _idUsuario }
      )
      .pipe(retry(3));
  }

  obtenerDatosUnidad(_idUsuario: string, _idCurso: string, _idUnidad: string) { // EN USO
    return this.http
      .post<any>(
        environment.url + "Controlador_registro_curso/obtenerDatosUnidad",
        { idCurso: _idCurso, idUsuario: _idUsuario, idUnidad: _idUnidad }
      )
      .pipe(retry(3));
  }

  obtenerDatosTema(_idUsuario: string, _idCurso: string, _idTema: string) { // EN USO
    return this.http
      .post<any>(
        environment.url + "Controlador_registro_curso/obtenerDatosTema",
        { idCurso: _idCurso, idUsuario: _idUsuario, idTema: _idTema }
      )
      .pipe(retry(3));
  }

  /******************************************************/

  guardarUnidad(_formData: FormData) { // EN USO
    return this.http.post(
      environment.url + "Controlador_registro_curso/guardarUnidad",
      _formData
    );
  }

  guardarTemaNuevo(_formData: FormData) {
    return this.http.post(
      environment.url + "Controlador_registro_curso/guardarTemaNuevo",
      _formData
    );
  }

  guardarMaterial(_formData: FormData): Observable<HttpEvent<any>> {
    return this.http.post(
      environment.url + "Controlador_registro_curso/guardarMaterial",
      _formData,
      {
        reportProgress: true,
        observe: "events"
      }
    );
  }
  /******************************************************/

  // Estas funcionan para ediciones cuando las unidades ya estan en el listado de unidades
  actualizarDatosUnidad(_formData: FormData) { // EN USO
    return this.http.post(
      environment.url + "Controlador_registro_curso/actualizarUnidad",
      _formData
    );
  }

  actualizarDatosTema(_formData: FormData) { // EN USO
    return this.http.post(
      environment.url + "Controlador_registro_curso/actualizarTema",
      _formData
    );
  }

  actualizarMateriales(_formData: FormData): Observable<HttpEvent<any>> { // EN USO
    return this.http.post(
      environment.url + "Controlador_registro_curso/actualizarMaterial",
      _formData,
      {
        reportProgress: true,
        observe: "events"
      }
    );
  }

  //************ GUARDADO DE ORDEN ********************

  guardarOrdenUnidad(_ordenArray: any) {
    return this.http.post(
      environment.url + "Controlador_registro_curso/guardarOrdenUnidad", { ordenArray: _ordenArray });
  }

  guardarOrdenTema(_ordenArray: any) {
    return this.http.post(
      environment.url + "Controlador_registro_curso/guardarOrdenTema", { ordenArray: _ordenArray });
  }
  /******************************************************/

  // Eliminacion de contenido (unidades/ temas)
  eliminarTema(_idTema: string, _idCurso: string, _idUsuario: string) { // EN USO
    return this.http.post(
      environment.url + "Controlador_registro_curso/eliminarTema",
      {
        idTema: _idTema,
        idCurso: _idCurso,
        idUsuario: _idUsuario
      }
    );
  }

  eliminarUnidad(_idUnidad: string, _idCurso: string, _idUsuario: string) { // EN USO
    return this.http.post(
      environment.url + "Controlador_registro_curso/eliminarUnidad",
      {
        idUnidad: _idUnidad,
        idCurso: _idCurso,
        idUsuario: _idUsuario
      }
    );
  }

  eliminarExamen(_formData: FormData) { // EN USO
    return this.http.post<any>(
      environment.url + "Controlador_registro_examen/eliminarExamen", _formData);
  }

  eliminarExamenFinal(_formData: FormData) { // EN USO
    return this.http.post<any>(
      environment.url + "Controlador_registro_examen/eliminarExamenFinal", _formData);
  }
  /******************************************************/

  asignarTipoConstancia(_formData: FormData) { // EN USO
    return this.http.post<Boolean>(
      environment.url + "Controlador_registro_curso/asignarTipoConstancia", _formData);
  }

  asignarPrecio(_formData: FormData) { // EN USO
    return this.http.post(
      environment.url + "Controlador_registro_curso/asignarPrecio", _formData);
  }

  asignarDuracion(_idCurso: string) {
    return this.http.post(
      environment.url + "Controlador_registro_curso/actualizaDuracionUnidadCurso", { idCurso: _idCurso });
  }

  actualizarEstatusPaso3(_idCurso: string) {
    return this.http.post(
      environment.url + "Controlador_registro_curso/actualizarEstatusCursoPaso3", { idCurso: _idCurso });
  }

  validaExistenciaExamenFinal(_idCurso: string) { // EN USO
    return this.http.post(
      environment.url + "Controlador_registro_curso/validaExistenciaExamenFinal", { idCurso: _idCurso });
  }

  verificarEmpresaCliente(_idCurso: string) {
    return this.http
      .post(
        environment.url + "Controlador_registro_curso/verificarEmpresaCliente",
        { idCurso: _idCurso }
      )
      .pipe(retry(3));
  }

  obtenerOcupaciones() {
    return this.http
      .get<Array<any>>(
        environment.url + "Controlador_registro_curso/obtenerOcupaciones"
      )
      .pipe(retry(3));
  }

  obtenerDatosConstancia(_idCurso: string) {
    try {
      return this.http.get<{ status: string, tipoUsuario: string, tipoConstancia: any, datosConstancia: any, aulaVirtual: string }>(
        environment.url + "Controlador_registro_curso/obtenerDatosConstanciaPaso4?idCurso=" + _idCurso).toPromise();
    } catch (error) {
      this.modalesService.lanzarModalError(
        "Upps! algo salio mal",
        "Intentelo más tarde",
        () => {
          console.log(error);
        }
      );
    }
  }

  obtenerRaxonSocial(_idCurso: string) {
    return this.http
      .post(
        environment.url + "Controlador_registro_curso/obtenerRaxonSocial",
        { idCurso: _idCurso }
      )
      .pipe(retry(3));
  }

  generarCodigos(_formData: FormData) {
    return this.http.post(
      environment.url + "Controlador_registro_curso/generarCodigos", _formData);
  }

  //*********************** Guard *******************************************************************/
  verificarPasoCuatro(_idCurso: string): Promise<any> {

    try {
      return this.http.post(
        environment.url + "Controlador_registro_curso/verificarPasoCuatro", { idCurso: _idCurso }).toPromise();
    } catch (error) {
      this.modalesService.lanzarModalError(
        "Upps! algo salio mal",
        "Intentelo más tarde",
        () => {
          console.log(error);
        }
      );
    }

  }



}
