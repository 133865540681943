import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfessorGuard } from './core/guards/professor.guard';
import { AdministradorGuard } from './core/guards/administrador.guard';

const routes: Routes = [


  // { path: 'empresa', loadChildren: () => import('./plataformas/sigash/sigash.module').then(m => m.SigashModule) }

  {
    path: 'profesor',
    canLoad: [ProfessorGuard],
    loadChildren: () => import('./plataformas/online/profesor/profesor.module').then(m => m.ProfesorModule)
  },

  {
    path: 'administrador',
    canLoad: [AdministradorGuard],
    loadChildren: () => import('./plataformas/online/administrador/administrador.module').then(m => m.AdministradorModule)
  },

  {
    path: '',
    loadChildren: () => import('./plataformas/online/online.module').then(m => m.OnlineModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
