import { Action } from '@ngrx/store';

export const INICIO_SESION = 'INICIAR_SESION';
export const CERRAR_SESION = 'CERRAR_SESION';

export class InicioSesion implements Action {
    readonly type = INICIO_SESION;

    constructor(
        public payload: any
    ) { }
}

export class CerrarSesion implements Action {
    readonly type = CERRAR_SESION;

    constructor(
        public payload: any
    ) { }
}

export type Acciones = InicioSesion | CerrarSesion;