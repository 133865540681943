<ul class="d-none d-lg-block d-xl-block d-md-none sidebar">
    <div *ngIf="!menuAdmin && !menuColaborador">

        <li routerLink="/perfil"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-perfil my-2">
                    </div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left"
                          *ngIf="!abrirSidebar">Perfil</span>
                </div>
            </div>
        </li>

        <!-- Enlace para empresas cliente * SOLO SIGASH * -->
        <li routerLink="/profesor/aula-virtual/home"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}"
            *ngIf="tipoRol == '5'">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-emplesaCliente my-2"></div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-1"
                          *ngIf="!abrirSidebar">Aula virtual</span>
                </div>
            </div>
        </li>


        <li routerLink="/mis-cursos"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-misCursos my-2"></div>
                </div>
                <div class="col-8 pl-2 text-left my-auto">
                    <span class="h6 text-left"
                          *ngIf="!abrirSidebar">Aprender</span>
                </div>
            </div>
        </li>

        <li routerLink="/profesor/home"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}"
            *ngIf="mostrarCursos">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-cursosCreados my-2"></div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left text-break"
                          *ngIf="!abrirSidebar">Enseñar</span>
                </div>
            </div>

        </li>
        <li routerLink="/beneficios-de-enseñar"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}"
            *ngIf="!mostrarCursos">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-cursosCreados my-2"></div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-1"
                          *ngIf="!abrirSidebar">Enseñar</span>
                </div>
            </div>

        </li>
        <li routerLink="/home"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-categorias my-2"></div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-1"
                          *ngIf="!abrirSidebar">Categorías</span>
                </div>
            </div>
        </li>
        <li routerLink="/finanzas"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}"
            *ngIf="!mostrarCursos">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-finanzas my-2"></div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-1"
                          *ngIf="!abrirSidebar">Finanzas</span>
                </div>
            </div>
        </li>
        <li routerLink="/profesor/finanzas"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}"
            *ngIf="mostrarCursos">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-finanzas my-2"></div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-1"
                          *ngIf="!abrirSidebar">Finanzas</span>
                </div>
            </div>
        </li>
        <li routerLink="/profesor/cupones-y-descuentos"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}"
            *ngIf="mostrarCursos">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-cupon my-2">

                    </div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-2"
                          *ngIf="!abrirSidebar">Cupones</span>
                </div>
            </div>
        </li>

    </div>


    <!-- MENU ADMINISTRADOR -->
    <div *ngIf="menuAdmin || menuColaborador">

        <li *ngIf="menuAdmin"
            routerLink="/administrador/colaboradores"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-colaborador my-2"></div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-2"
                          *ngIf="!abrirSidebar">Colaboradores</span>
                </div>
            </div>
        </li>

        <li *ngIf="menuColaborador"
            routerLink="/administrador/cursos-por-validar"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-ValidarCurso my-2"></div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-1"
                          *ngIf="!abrirSidebar">Validar cursos</span>
                </div>
            </div>
        </li>

        <li *ngIf="menuAdmin"
            routerLink="/administrador/listado-estatus-curso-validacion"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-ValidarCurso my-2"></div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-1"
                          *ngIf="!abrirSidebar">Validar cursos</span>
                </div>
            </div>
        </li>

        <li *ngIf="menuAdmin"
            routerLink="/administrador/ventas"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-finanzas my-2 ">

                    </div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-2"
                          *ngIf="!abrirSidebar">Ventas</span>
                </div>
            </div>
        </li>

        <li *ngIf="menuAdmin"
            routerLink="/administrador/estadisticos"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-estadisticas my-2 ">

                    </div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-2"
                          *ngIf="!abrirSidebar">Estadísticos</span>
                </div>
            </div>
        </li>

        <li *ngIf="menuAdmin"
            routerLink="/administrador/registro-articulo"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-cursosCreados my-2 ">
                    </div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-2"
                          *ngIf="!abrirSidebar">Articulos</span>
                </div>
            </div>
        </li>

        <li *ngIf="menuAdmin"
            routerLink="/administrador/listado-solicitudes-de-contacto"
            routerLinkActive="active"
            routerLinkActiveOptions="{exact: true}">
            <div class="row p-1">
                <div class="col-3">
                    <div class="img-responsive img-menu-solicitudesContacto my-2 ">
                    </div>
                </div>
                <div class="col-8 pl-1 text-left my-auto">
                    <span class="h6 text-left mr-2"
                          *ngIf="!abrirSidebar">Solicitudes de contacto</span>
                </div>
            </div>
        </li>
    </div>

    <li (click)="cerrarSesion()">
        <div class="row p-1">
            <div class="col-3">
                <div class="img-responsive img-menu-cerrarSesion my-2"></div>
            </div>
            <div class="col-8 pl-1 text-left my-auto">
                <span class="h6 text-left mr-2"
                      *ngIf="!abrirSidebar">Cerrar Sesión</span>
            </div>
        </div>
    </li>
</ul>