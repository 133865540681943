// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  url: 'https://api.pruebas.siigue.com/',

  urlImagenes: 'https://s3.us-east-2.amazonaws.com/multimedia.pruebas.siigue.com/assets/online/',

  configDatatable: {
    pagingType: 'simple_numbers',
    language: {
      decimal: '',
      emptyTable: 'Sin datos disponibles',
      info: '',
      infoEmpty: '',
      infoFiltered: '(filtrado de _MAX_ total resultados)',
      infoPostFix: '',
      thousands: ',',
      lengthMenu: 'Mostrar _MENU_ resultados',
      loadingRecords: 'Cargando...',
      processing: 'Procesando...',
      search: 'Buscar:',
      zeroRecords: 'No se encontraron resultados',
      aria: {
        sortAscending: ': ordenar columna ascendientemente',
        sortDescending: ': ordenar columna descendientemente'
      },
      paginate: {
        first: 'First',
        last: 'Last',
        next: 'Siguiente',
        previous: 'Anterior'
      },
    }
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
