import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { authService } from 'src/app/core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdministradorGuard implements CanActivate, CanLoad {

  // * Variable para guardar la informacion de Usuario
  infoSesion: any;

  constructor(
    private authSer: authService,
    private router: Router
  ) {

    // * Obtenemos datos de sesión
    this.authSer.obtenerDatosSesion().subscribe(
      respuesta => {
        this.infoSesion = respuesta;
      }
    );

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.infoSesion) {
      return true;
    }

    return this.router.navigate(['/home']);

  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    if (
      this.infoSesion.tipoRol === '6' || // * Administrador
      this.infoSesion.tipoRol === '7' // * Colaborador
    ) {
      return true;
    }

    return this.router.navigate(['/home']);

  }
}
