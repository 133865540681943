import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  // Variable que guard termino de busqueda de categorias
  terminoBusqueda:string;


  /* Variable que guarda tipo de busquerda
     1 si es una busqueda por curso
     2 si es una busqueda por categoria */
  tipoDeBusqueda:string;

  constructor(private http: HttpClient) { }

  obtenerCategorias() {
    return this.http.get(environment.url + 'Controlador_landing/obtenerCategorias');
  }

  enviarTerminoBusqueda(_terminoBusqueda, _tipoDeBusqueda) {
    this.terminoBusqueda = _terminoBusqueda;
    this.tipoDeBusqueda = _tipoDeBusqueda;
  }  

}
