import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// * Importación de SweetAlert para Angular
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// DIRECTIVES
import { SoloLetrasDirective } from './directives/solo-letras.directive';
import { SoloNumerosDirective } from './directives/solo-numeros.directive';
import { SoloNumerosLetrasDirective } from './directives/solo-numeros-letras.directive';
import { InputTrimDirective } from './directives/trim.directive';
import { NumericDirective } from './directives/solo-numeros-decimales.directive';
import { PhoneMaskDirective } from './directives/mascara-telefono.directive';
import { MayusculasInputDirective } from './directives/mayusculas.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from "ngx-spinner";

// PIPES
import { telefonoPipe } from './pipes/telefono.pipe';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { ModalRegistroComponent } from './components/modal-registro/modal-registro.component';
import { ModalRecuperarContrasenaComponent } from './components/modal-recuperar-contrasena/modal-recuperar-contrasena.component';
import { ModalResultadoPositivoExamenComponent } from './components/modal-resultado-positivo-examen/modal-resultado-positivo-examen.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { HeaderMovilComponent } from './header-movil/header-movil.component';
import { SidebarComponent } from './sidebar/sidebar.component';

// * Libreria de Captcha
import { NgxCaptchaModule } from 'ngx-captcha';
import { ReproductorVideoComponent } from './components/reproductor-video/reproductor-video.component';

// Importaciones video Angular
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';

import { MAT_DATE_LOCALE } from '@angular/material';

import { ClipboardModule } from 'ngx-clipboard';

// * Imports de Material
import { MatIconModule } from '@angular/material';
import { ModalSubiendoArchivoComponent } from './components/modal-subiendo-archivo/modal-subiendo-archivo.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';


import { InterceptorHttpService } from '../core/auth/interceptor-http.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Components
import { StripeConnectButtonComponent } from './components/stripe-connect-button/stripe-connect-button.component';
import { StripePayButtonComponent } from './components/stripe-pay-button/stripe-pay-button.component';
import { StripeDashboardButtonComponent } from './components/stripe-dashboard-button/stripe-dashboard-button.component';
import { FooterComponent } from './footer/footer.component';
import { SaldoComponent } from './components/saldo-usuario/saldo/saldo.component';
import {StripeBankAccountInfoComponent} from './components/stripe-bank-account-info/stripe-bank-account-info/stripe-bank-account-info.component';
import {ReactiveFormsModule} from '@angular/forms';

// Declaration es para componentes y directivos
// Todo lo que se declare se tiene que exportar para poder usarse correctamente
// No se puede declarar un componente en mas de un modulo

@NgModule({
    declarations: [
        SoloLetrasDirective,
        SoloNumerosDirective,
        SoloNumerosLetrasDirective,
        InputTrimDirective,
        NumericDirective,
        PhoneMaskDirective,
        MayusculasInputDirective,
        telefonoPipe,
        ModalLoginComponent,
        ModalRegistroComponent,
        ModalRecuperarContrasenaComponent,
        ModalResultadoPositivoExamenComponent,
        HeaderComponent,
        HeaderMovilComponent,
        SidebarComponent,
        ReproductorVideoComponent,
        ModalSubiendoArchivoComponent,
        ReproductorVideoComponent,
        StripeConnectButtonComponent,
        StripePayButtonComponent,
        StripeDashboardButtonComponent,
        FooterComponent,
        SaldoComponent,
        StripeBankAccountInfoComponent
    ],
    imports: [
        CommonModule,
        InfiniteScrollModule,
        NgxSpinnerModule,
        SweetAlert2Module,
        FontAwesomeModule,
        RouterModule,
        NgxCaptchaModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        ClipboardModule,
        MatIconModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        ReactiveFormsModule
    ],
    exports: [
        SoloLetrasDirective,
        SoloNumerosDirective,
        SoloNumerosLetrasDirective,
        InputTrimDirective,
        NumericDirective,
        PhoneMaskDirective,
        MayusculasInputDirective,
        telefonoPipe,
        InfiniteScrollModule,
        NgxSpinnerModule,
        SweetAlert2Module,
        ModalResultadoPositivoExamenComponent,
        HeaderComponent,
        HeaderMovilComponent,
        SidebarComponent,
        NgxCaptchaModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        ReproductorVideoComponent,
        StripeConnectButtonComponent,
        StripePayButtonComponent,
        StripeDashboardButtonComponent,
        ClipboardModule,
        MatIconModule,
        ModalSubiendoArchivoComponent,
        MatTooltipModule,
        MatProgressSpinnerModule,
        FooterComponent,
        SaldoComponent,
        StripeBankAccountInfoComponent
    ],
  providers: [
    telefonoPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorHttpService,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es-ES'
    }
  ]

})
export class SharedModule {
  constructor(_library: FaIconLibrary) {
    _library.addIconPacks(fas, fab, far);
  }
}
