<div class="d-none d-lg-block">

    <nav class="header-custom">
        <div *ngIf="sesionIniciada">
            <!-- Iconos y funcion checkbox para abrir y cerrar menu -->
            <input type="checkbox"
                   id="check">
            <label for="check"
                   class="checkbtn mt-3">
                <fa-icon style="font-size: 1.4rem; color: gray;"
                         class="bg-gris p-2 rounded-bar-custom"
                         [icon]="['fas', 'chevron-left']"
                         (click)="abrirMenu()"
                         *ngIf="mostrarTexto"></fa-icon>
                <fa-icon style="font-size: 1.4rem; color: gray;"
                         class="bg-gris p-2 rounded-bar-custom"
                         [icon]="['fas', 'chevron-right']"
                         (click)="abrirMenu()"
                         *ngIf="!mostrarTexto"></fa-icon>

            </label>

        </div>

        <!-- Logo SIIGE -->
        <img class="logo ml-3 mt-3 float-left"
             src="/assets/img/LOGO-SIIGUE-BLANCO.png"
             style="width: 11rem;cursor:pointer;"
             routerLink="/home">

        <!-- Buscador -->
        <div *ngIf="!esAdminColaborador"
             class="col-5 col-md-3 float-left pl-5 mt-4">
            <div class="nav-search">
                <div class="search">
                    <i class="btn btn-verdeV2"
                       (click)="relizarBusquedaCategoria(search.value, 1)">
                        <fa-icon [icon]="['fas', 'search']"></fa-icon>
                    </i>
                    <input type="search"
                           class="text-left"
                           name="search"
                           placeholder="Realiza tu búsqueda"
                           #search
                           (keyup.enter)="relizarBusquedaCategoria(search.value, 1)">
                </div>
            </div>
        </div>

        <div *ngIf="sesionIniciada">
            <!-- Nombre usuario -->
            <div class=" col-12 col-md-auto float-right mt-2 d-none d-md-block"
                 *ngIf="sesionIniciada">

                <div class="row">

                    <div class="col-auto pr-2">
                        <span class="icono-custom"
                              style="cursor: pointer;"
                              routerLink="/notificaciones">
                            <span class="sr-only">Numero de notificaciones</span>
                            <h5 class="badge badge-pill badge-danger px-2 py-1 float-right"
                                *ngIf="numeroNotificaciones > 0">{{ numeroNotificaciones }}</h5>
                            <fa-icon style="font-size: 2rem;"
                                     class="px-1 py-2 mt-0 text-white float-right icono-custom"
                                     [icon]="['fas', 'bell']">
                            </fa-icon>
                        </span>
                    </div>

                    <div *ngIf="!esAdminColaborador"
                         class="col-auto">
                        <span class="icono-custom"
                              style="cursor: pointer;"
                              routerLink="/carrito">
                            <span class="sr-only">Elementos en el carrito</span>
                            <h5 class="badge badge-pill badge-danger px-2 py-1 float-right"
                                *ngIf="numeroElementosCarrito > 0">{{ numeroElementosCarrito }}</h5>
                            <fa-icon style="font-size: 2rem;"
                                     class="px-1 py-2 mt-0 text-white float-right icono-custom"
                                     [icon]="['fas', 'shopping-cart']">
                            </fa-icon>
                        </span>
                    </div>

                    <div class="col-auto my-auto">
                        <span class="h6 m-4 text-white">{{nombreUsuario}}</span>
                        <div class="row justify-content-center"
                             *ngIf="nombreRol">
                            <small class="text-white">{{nombreDeRol}}</small>

                        </div>
                    </div>



                </div>
            </div>
        </div>

        <div *ngIf="!sesionIniciada">
            <!-- Nombre usuario -->
            <div class=" col-4 float-right mt-4">
                <div class="row justify-content-around">
                    <div class="col-5">
                        <button class="btn btn-block btn-grisClaroV2 btn-sombra py-2"
                                (click)="modalLogin()">
                            <span>
                                Iniciar sesión
                            </span>
                        </button>
                    </div>
                    <div class="col-5">
                        <button class="btn btn-block btn-verdeV2 btn-sombra py-2"
                                (click)="modalRegistro()">
                            <span>
                                Regístrate
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </nav>
</div>

<div class="d-block d-lg-none">

    <nav class="navbar navbar-expand-lg navbar-light shadow header-custom-movil"
         *ngIf="iniciarCerrarSesion">

        <!-- logo -->
        <img class="float-right ml-2 mt-1"
             src="/assets/img/LOGO-SIIGUE-BLANCO.png"
             style="width: 5rem;"
             routerLink="/home">

        <span class="icono-custom ml-auto mr-1"
              style="cursor: pointer;"
              routerLink="/notificaciones">
            <span class="sr-only">Numero de notificaciones</span>
            <h6 class="badge badge-pill badge-danger px-2 py-1 float-right mr-2"
                *ngIf="numeroNotificaciones > 0">{{ numeroNotificaciones }}</h6>
            <fa-icon style="font-size: 1.5rem;"
                     class="px-1 py-2 mt-0 text-white float-right icono-custom"
                     [icon]="['fas', 'bell']">
            </fa-icon>
        </span>

        <span *ngIf="!esAdminColaborador"
              class="icono-custom mx-2"
              style="cursor: pointer;"
              routerLink="/carrito">
            <span class="sr-only">Elementos en el carrito</span>
            <h6 class="badge badge-pill badge-danger px-2 py-1 float-right mr-2"
                *ngIf="numeroElementosCarrito > 0">{{ numeroElementosCarrito }}</h6>
            <fa-icon style="font-size: 1.5rem;"
                     class="px-1 py-2 mt-0 text-white float-right icono-custom"
                     [icon]="['fas', 'shopping-cart']">
            </fa-icon>
        </span>

        <button class="ml-2 mx-3
         navbar-toggler float-left bg-gris"
                type="button"
                data-toggle="collapse"
                data-target="#opciones">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- enlaces -->
        <div class="collapse navbar-collapse"
             id="opciones">
            <ul class="navbar-nav mt-3">
                <!-- Search -->
                <li class="nav-item mt-0 mb-3 p-0 text-left">
                    <div class="row justify-content-center">
                        <div class="nav-search">
                            <div class="search">
                                <i class="btn"
                                   #botonBuscar
                                   (click)="relizarBusquedaCategoria(search.value, 1)"
                                   data-toggle="collapse"
                                   data-target="#opciones">
                                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                                </i>
                                <input type="search"
                                       class="text-left"
                                       name="search"
                                       placeholder="Realiza tu búsqueda"
                                       #search
                                       (keyup.enter)="buscarEnter()">
                            </div>
                        </div>
                    </div>
                </li>

                <li class="nav-item mt-0 p-0 text-left estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/perfil"
                    data-toggle="collapse"
                    data-target="#opciones">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-perfil">
                            </div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Perfil</span>
                        </div>
                    </div>
                </li>

                <li class="nav-item mt-0 p-0 text-left estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/administrador/estadisticos"
                    data-toggle="collapse"
                    data-target="#opciones"
                    *ngIf="tipoRol == '6'">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-estadisticas">
                            </div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Estadísticos</span>
                        </div>
                    </div>
                </li>

                <!-- Enlace para empresas cliente * SOLO SIGASH * -->
                <li class="nav-item mt-0 p-0 text-left border-top estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/profesor/aula-virtual/home"
                    data-toggle="collapse"
                    data-target="#opciones"
                    *ngIf="tipoRol == '5'">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-emplesaCliente"></div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Aula Virtual</span>
                        </div>
                    </div>
                </li>

                <li class="nav-item mt-0 p-0 text-left  border-top estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/mis-cursos"
                    data-toggle="collapse"
                    data-target="#opciones">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-misCursos"></div>

                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Aprender</span>
                        </div>
                    </div>
                </li>
                <li class="nav-item mt-0 p-0 text-left border-top estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/profesor/home"
                    data-toggle="collapse"
                    data-target="#opciones"
                    *ngIf="mostrarCursos">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-cursosCreados"></div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Enseñar</span>
                        </div>
                    </div>
                </li>
                <li class="nav-item mt-0 p-0 text-left border-top estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/beneficios-de-enseñar"
                    data-toggle="collapse"
                    data-target="#opciones"
                    *ngIf="!mostrarCursos">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-cursosCreados"></div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Enseñar</span>
                        </div>
                    </div>
                </li>


                <li class="nav-item mt-0 p-0 text-left border-top estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/home"
                    data-toggle="collapse"
                    data-target="#opciones">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-categorias"></div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Categorías</span>
                        </div>
                    </div>
                </li>
                <li class="nav-item mt-0 p-0 text-left border-top estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/finanzas"
                    data-toggle="collapse"
                    data-target="#opciones"
                    *ngIf="!mostrarCursos">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-finanzas"></div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Finanzas</span>
                        </div>
                    </div>
                </li>
                <li class="nav-item mt-0 p-0 text-left border-top estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/profesor/finanzas"
                    data-toggle="collapse"
                    data-target="#opciones"
                    *ngIf="mostrarCursos">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-finanzas"></div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Finanzas</span>
                        </div>
                    </div>
                </li>

                <li class="nav-item mt-0 p-0 text-left border-top estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    routerLink="/profesor/cupones-y-descuentos"
                    data-toggle="collapse"
                    data-target="#opciones"
                    *ngIf="mostrarCursos">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-cupon">

                            </div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Cupones</span>
                        </div>
                    </div>
                </li>
                <li class="nav-item mt-0 p-0 text-left border-top border-bottom estilo-movil"
                    routerLinkActive="active"
                    routerLinkActiveOptions="{exact: true}"
                    data-toggle="collapse"
                    data-target="#opciones"
                    (click)="cerrarSesion()">
                    <div class="row justify-content-center">
                        <div class="col-2 py-3">
                            <div class="img-menu-cerrarSesion"></div>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Cerrar Sesión</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>

    <!-- ngif -->
    <nav class="navbar navbar-expand-lg navbar-light header-custom-movil py-3"
         *ngIf="!iniciarCerrarSesion">

        <!-- logo -->
        <img class="float-right ml-2 mt-1"
             src="/assets/img/LOGO-SIIGUE-BLANCO.png"
             style="width: 5rem;"
             routerLink="/home">

        <button class="navbar-toggler float-left bg-gris mx-3"
                type="button"
                data-toggle="collapse"
                data-target="#opciones">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- enlaces -->
        <div class="collapse navbar-collapse"
             id="opciones">
            <ul class="navbar-nav mt-3">
                <!-- Search -->
                <li class="nav-item mt-0 mb-3 p-0 text-left">
                    <div class="row justify-content-center">
                        <div class="nav-search">
                            <div class="search">
                                <i class="btn btn-azul"
                                   (click)="relizarBusquedaCategoria(search.value, 1)"
                                   data-toggle="collapse"
                                   data-target="#opciones">
                                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                                </i>
                                <input type="search"
                                       class="text-left"
                                       name="search"
                                       placeholder="Realiza tu busqueda"
                                       #search
                                       (keyup.enter)="relizarBusquedaCategoria(search.value, 1)">
                            </div>
                        </div>
                    </div>
                </li>
                <!-- Iniciar Sesion -->
                <li class="nav-item mt-0 p-0 text-left border-top-0 border-bottom estilo-movil"
                    data-toggle="collapse"
                    data-target="#opciones"
                    (click)="modalLogin()">
                    <div class="row justify-content-center">
                        <div class="col-2">
                            <a class="p-3"><img class="img-custom"
                                     src="/assets/img/iconos-menu-svg-v2/icono_iniciar_sesion.svg"
                                     routerLinkActiveOptions="{exact: true}"></a>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Iniciar Sesión</span>
                        </div>
                    </div>
                </li>
                <li class="nav-item mt-0 p-0 text-left border-top-0 border-bottom estilo-movil"
                    data-toggle="collapse"
                    data-target="#opciones"
                    (click)="modalRegistro()">
                    <div class="row justify-content-center">
                        <div class="col-2">
                            <a class="p-3"><img class="img-custom"
                                     src="/assets/img/iconos-menu-svg-v2/icono_registro.svg"
                                     routerLinkActiveOptions="{exact: true}"></a>
                        </div>
                        <div class="col-7 my-auto">
                            <span class="h6 pl-3">Regístrate</span>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    </nav>

</div>