import { Component, OnInit, Input } from "@angular/core";
import { authService } from "src/app/core/auth/auth.service";
import { Modales } from "../services/modales.service";
import { Router } from "@angular/router";
import * as acciones from "src/app/core/sesionStore/sesion.action";
import { Store } from "@ngrx/store";
import { ComunicacionModalesService } from "src/app/plataformas/online/services/comunicacion-modales.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  // * Variables de tipo de rol
  tipoRol: string;

  //* Muestra y oculta la opcion de mis cursos creados de menu según el rol
  mostrarCursos = false;
  ocultarCampo = false;
  menuAdmin = false;
  menuColaborador = false;

  @Input() abrirSidebar: boolean;

  constructor(
    private autService: authService,
    private servicioModales: Modales,
    private router: Router,
    private store: Store,
    private servicioComunicacionModales: ComunicacionModalesService
  ) {
    // * Obtenemos los datos del usuario en sesion
    this.autService.obtenerDatosSesion().subscribe((res) => {
      // validacion de tipo de rol

      if (res) {
        this.ocultarCampo = true;
      }

      this.tipoRol = res.tipoRol;

      if (this.tipoRol !== "1" && this.tipoRol !== "8") {

        this.mostrarCursos = true;
      }
      if (this.tipoRol == "6") {

        this.menuAdmin = true;
      }
      if (this.tipoRol == "7") {

        this.menuColaborador = true;
      }
    });
  }

  ngOnInit(): void { }

  // Boton de cerrar sesion
  cerrarSesion() {
    this.servicioModales.lanzarModalConfirmar(
      "¿Estás seguro que quieres cerrar sesión?",
      '',
      () => {
        this.autService.cerrarSesion();
        this.ocultarCampo = false;
        this.mostrarCursos = false;
        window.location.replace("/");
      }
    );
  }

  // Modal inicio de sesion
  modalLogin() {
    this.servicioComunicacionModales.loginActivado$.next(true);
  }
}
