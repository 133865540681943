import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { authService } from 'src/app/core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfessorGuard implements CanActivate, CanLoad {

  // * Variable para guardar la informacion de Usuario
  infoSesion: any;

  constructor(
    private authSer: authService,
    private router: Router
  ) {

    // * Obtenemos datos de sesión
    this.authSer.obtenerDatosSesion().subscribe(
      respuesta => {
        this.infoSesion = respuesta;
      }
    );

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.infoSesion) {
      return true;
    }

    return this.router.navigate(['/home']);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    if (
      this.infoSesion.tipoRol === '2' || // * Instructor independiente
      this.infoSesion.tipoRol === '3' || // * Empresa Siigue
      this.infoSesion.tipoRol === '4' || // * Empresa Especial
      this.infoSesion.tipoRol === '5' || // * Empresa Sigash
      this.infoSesion.tipoRol === '9' || // * Empresa Sigash
      this.infoSesion.tipoRol === '10' // * Empresa Sigash
    ) {
      return true;
    }

    return this.router.navigate(['/home']);
  }

}
