import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEventType } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InterceptorHttpService implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(tap(
            (event) => {
                if (event.type === HttpEventType.UploadProgress) {

                }

                if (event.type === HttpEventType.Response) {

                }

            }
        ));
    }
}