import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HostListener } from '@angular/core';

// SERVICIOS
import { authService } from './core/auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  animateChild,
  state
} from '@angular/animations';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // estilos para animaciones de menu
  animations: [
    trigger('animacionSidebar', [
      state('abrir', style({
        width: '*',
      })),
      state('cerrar', style({
        width: '*',
      })),
      transition('abrir <=> cerrar', [
        style({
          display: 'block',
        }),
        animate('0.30s ease-in-out')
      ]),
    ])
  ]

})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('contenedorContenido', { read: ElementRef }) public contenedorContenido: ElementRef<any>;

  screenHeight: number;
  screenWidth: number;

  title = 'Siigue';
  isActivated = 'abrir';
  menuAbierto = false;

  sesionIniciada = false;

  constructor(
    private authService: authService,
    private router: Router
  ) {
    // * Obtenemos los datos del usuario en sesion
    this.authService.obtenerDatosSesion().subscribe(res => {

      if (res) {
        this.sesionIniciada = true;
      }

    });

    authService.recuperarInforUsuarioStore();

    // this.getScreenSize();
  }

  // @HostListener('window:resize', ['$event'])
  // getScreenSize(event?) {
  //   this.screenHeight = window.innerHeight;
  //   this.screenWidth = window.innerWidth;
  // }

  ngAfterViewInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (!this.router.url.includes('?tab=')) {
        this.contenedorContenido.nativeElement.scrollTo(0, -90);
      }
    });
  }

  ngOnInit() { }

  mostrarMenu() {
    if (
      (this.router.url.includes('/verificacion-nombre')) ||
      (this.router.url === '/bienvenida') ||
      (this.router.url.includes('/recuperacion/')) ||
      (this.router.url === '/terminos-y-condiciones') ||
      (this.router.url === '/registro-exitoso') ||
      (this.router.url === '/profesor/registro-cuenta-clabe') ||
      (this.router.url.includes('/curso/'))
      // (this.router.url.includes('/examen'))
    ) {

      return false;
    } else {
      return true;
    }
  }

  abrirSideBar(_datos) {
    this.menuAbierto = _datos;
    this.isActivated = 'cerrar';
    if (_datos === false) {
      this.isActivated = 'abrir';
    }
  }

}

declare var OpenPay: any;
