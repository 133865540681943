import { Component, OnInit, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Modales } from '../../services/modales.service';
import { RegistrarCursoService } from 'src/app/plataformas/online/profesor/registro-contenido-curso/services/registrar-curso.service';
import { HttpEventType } from '@angular/common/http';
import { ComunicacionComponentesService } from 'src/app/plataformas/online/profesor/registro-contenido-curso/services/comunicacion-componentes.service';

@Component({
  selector: 'app-modal-subiendo-archivo',
  templateUrl: './modal-subiendo-archivo.component.html',
  styleUrls: ['./modal-subiendo-archivo.component.scss']
})
export class ModalSubiendoArchivoComponent implements OnInit, OnDestroy {

  infoTemaSubiendo: any = [];

  progreso = [];
  mostrarProcesos = false;

  private subscription = new Subscription();

  constructor(
    private registroCursoService: RegistrarCursoService,
    private comunicacionCompService: ComunicacionComponentesService,
    private eRef: ElementRef,
    private modalService: Modales
  ) { }

  ngOnInit(): void {
    this.obtenerDatosForm();

    this.obtenerDatosEdicionForm();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.mostrarProcesos = false;
    }
  }

  observadorObtenerDatosForm() {
    return this.comunicacionCompService.formTemaSubir$.asObservable();
  }

  observadorObtenerDatosFormEdicion() {
    return this.comunicacionCompService.formTemaActualizar$.asObservable();
  }

  obtenerDatosForm() {
    this.subscription = this.observadorObtenerDatosForm().subscribe(
      (infoTema) => {
        this.guardarMaterial(infoTema.formData, infoTema.nombreTema);
      }
    );
  }

  guardarMaterial(_formData: FormData, _nombreTema: string) {
    this.registroCursoService.guardarMaterial(_formData).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) { // Escucha el porcentaje de subida
          let progress: any = Math.round(event.loaded / event.total * 100);

          let index = this.progreso.findIndex(x => x.nombre === _nombreTema);

          if (index === -1) {
            this.progreso.push({ nombre: _nombreTema, progreso: progress }); // Si no existe, lo agrega al arreglo
          } else {
            this.progreso[index] = { nombre: _nombreTema, progreso: progress }; // Si existe, actualiza su porcentaje
          }

        }

        if (event.type === HttpEventType.Response) { // Escucha cuando finaliza la subida

          let index = this.progreso.findIndex(x => x.nombre === _nombreTema);

          this.progreso[index] = { nombre: _nombreTema, progreso: 101 }; // Cuando es 101 cambia la barra de carga a indeterminada
          this.comunicacionCompService.actualizarListadoTemas$.next(true);// Manda llamar a la peticion http que consulta la info de la unidad

          setTimeout(() => { // Despues de finalizar, pasan 2 segundos y quita el elemento del arreglo y oculta el componente
            this.progreso.splice(index, 1);
            if (this.progreso.length === 0) {
              this.mostrarProcesos = false;
            }
          }, 2000);

        }
      }
    );
  }

  obtenerDatosEdicionForm() {
    this.subscription = this.observadorObtenerDatosFormEdicion().subscribe(
      (infoTema) => {
        this.actualizarTema(infoTema.formData, infoTema.nombreTema);
      }
    );
  }

  actualizarTema(_formData: FormData, _nombreTema: string) { // Cuit se la come

    this.registroCursoService.actualizarMateriales(_formData).subscribe(

      (event) => {
        if (event.type === HttpEventType.UploadProgress) { // Escucha el porcentaje de subida
          let progress: any = Math.round(event.loaded / event.total * 100);

          let index = this.progreso.findIndex(x => x.nombre === _nombreTema);

          if (index === -1) {
            this.progreso.push({ nombre: _nombreTema, progreso: progress }); // Si no existe, lo agrega al arreglo
          } else {
            this.progreso[index] = { nombre: _nombreTema, progreso: progress }; // Si existe, actualiza su porcentaje
          }

        }

        if (event.type === HttpEventType.Response) { // Escucha cuando finaliza la subida

          let index = this.progreso.findIndex(x => x.nombre === _nombreTema);

          this.progreso[index] = { nombre: _nombreTema, progreso: 101 }; // Cuando es 101 cambia la barra de carga a indeterminada
          this.comunicacionCompService.actualizarListadoTemas$.next(true); // Manda llamar a la peticion http que consulta la info de la unidad

          setTimeout(() => { // Despues de finalizar, pasan 2 segundos y quita el elemento del arreglo y oculta el componente
            this.progreso.splice(index, 1);
            if (this.progreso.length === 0) {
              this.mostrarProcesos = false;
            }
          }, 2000);

        }
      }, (error) => {
        this.modalService.lanzarModalError(
          'Upps! algo salio mal',
          'Intentelo más tarde',
          () => {
            let index = this.progreso.findIndex(x => x.nombre === _nombreTema);
            this.progreso.splice(index, 1);
            if (this.progreso.length === 0) {
              this.mostrarProcesos = false;
            }
            console.error(error);
          });
      }

    );
  }

  mostrarAvanceSubida(porcentajeProceso: number) {

    let respuesta;

    if (porcentajeProceso < 100) {
      respuesta = porcentajeProceso + '%';
    } else if (porcentajeProceso === 100) {
      respuesta = 'espere...';
    } else if (porcentajeProceso === 101) {
      respuesta = 'Ok!';
    }

    return respuesta;

  }

}
