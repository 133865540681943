import * as acciones from './sesion.action'

export const initialState = {
    usuario: false
}

export function reducerUsuario(_state: any = initialState, _action: acciones.Acciones) {

    switch (_action.type) {
        case acciones.INICIO_SESION:
            return {
                ..._state,
                usuario: _action.payload
            }
        case acciones.CERRAR_SESION:
            return {
                ..._state,
                usuario: false
            }
        default:
            return _state;
    }
}

export const getDatosSesion = (state) => state.datosSesion.usuario;