<div *ngIf="mostrarMenu()">

    <!-- Menu mobil -->

    <!-- Menu escritorio -->
    <div style="overflow-y: hidden;overflow-x: hidden;">
        <app-header (abrirSidebar)="abrirSideBar($event)"></app-header>
        <div class="row">

            <div #contenedorContenido
                 class="col estilos-row-contenido"
                 [ngClass]="{'pr-lg-0': sesionIniciada}"
                 [@animacionSidebar]="isActivated">
                <ng-container *ngTemplateOutlet="template"></ng-container>
            </div>

            <div class="col-auto overflow-hidden p-0"
                 style="min-height: calc(100vh - 90px); max-height: calc(100vh - 90px);"
                 *ngIf="sesionIniciada">
                <app-sidebar [abrirSidebar]="menuAbierto"></app-sidebar>
            </div>
        </div>

    </div>

</div>

<div *ngIf="!mostrarMenu()">
    <ng-container *ngTemplateOutlet="template"></ng-container>
</div>

<ng-template #template>
    <router-outlet></router-outlet>
</ng-template>

<div class="posicion">
    <app-modal-subiendo-archivo></app-modal-subiendo-archivo>
</div>