import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ComunicacionModalesService {

    // El lanzamiento de la modal de Login
    loginActivado$ = new Subject<boolean>();
    registroActivado$ = new Subject<boolean>();

    elementoAgregadoAlCarrito$ = new Subject<string>();
    elementoEliminadoDelCarrito$ = new Subject<string>();

    numeroDeNotificaciones$ = new Subject<string>();

    constructor() { }

}