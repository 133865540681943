import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class Modales {

    constructor() { }

    lanzarModalCargando(_titulo: string) {

        Swal.close();

        Swal.fire({
            position: 'center',
            title: _titulo + '...',
            text: 'Esto puede demorar unos minutos.',
            showConfirmButton: false,
            allowOutsideClick: false,
            heightAuto: false,
            onBeforeOpen: () => {

                Swal.showLoading();
                // setTimeout(() => {
                //     const content = Swal.getContent();
                //     if (content) {
                //         content.innerHTML = 'Esto puede demorar unos minutos.'
                //     }
                // }, 4000);
            },
        });
    }

    lanzarModalExito(_titulo: string, _mensaje: string, funcionOnClose: () => void) {

        Swal.close();

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            confirmButtonColor: '#077E37',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            timer: 2000,
            customClass: {
                title: 'titulo-modal-exito mb-5',
            },
            onClose: () => {

                funcionOnClose();
            }
        });

    }

    lanzarModalError(_titulo: string, _mensaje: string, funcionOnClose: () => void) {

        Swal.close();

        Swal.fire({
            position: 'center',
            icon: 'error',
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            confirmButtonColor: '#dc3545',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-error mb-5',
            },
            onClose: () => {

                funcionOnClose();
            }
        });

    }

    lanzarCursoAdquirido(_titulo: string, _mensaje: string, funcionOnClose: () => void) {

        Swal.close();

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            confirmButtonColor: '#077E37',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            timer: 6000,
            customClass: {
                title: 'titulo-modal-exito mb-5',
            },
            onClose: () => {
                funcionOnClose();
            }
        });

    }

    lanzarModalAdvertencia(_titulo: string, _mensaje: string, funcionOnClose: () => void) {

        Swal.close();

        Swal.fire({
            position: 'center',
            icon: 'info',
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            confirmButtonColor: '#00556a',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-confirm mb-5',
            },
            onClose: () => {

                funcionOnClose();
            }
        });

    }

    lanzarModalAdvertenciaAzul(_titulo: string, _mensaje: string, funcionOnClose: () => void) {

        Swal.close();

        Swal.fire({
            position: 'center',
            icon: 'info',
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            confirmButtonColor: '#00556a',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-confirm mb-5',
                image: 'texto-azul'
            },
            onClose: () => {

                funcionOnClose();
            }
        });

    }

    lanzarModalConfirmarEliminar(_titulo: string, _mensaje: string, funcionOnConfirm: () => void) {

        Swal.close();

        Swal.fire({
            imageUrl: 'assets/img/icon/Eliminacion.svg',
            imageWidth: 70,
            imageHeight: 70,
            imageAlt: 'Eliminar',
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#AEAEAE',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-error mb-5',
                image: 'text-danger'
            }
        }).then((result) => {
            if (result.value) {

                funcionOnConfirm();
            }
        });

    }

    lanzarModalConfirmarEditar(_titulo: string, _mensaje: string, funcionOnConfirm: () => void) {

        Swal.close();

        Swal.fire({
            imageUrl: 'assets/img/icon/Editar.svg',
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Editar',
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#00556a',
            cancelButtonColor: '#AEAEAE',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-edicion mb-5',
                image: 'text-danger'
            }
        }).then((result) => {
            if (result.value) {

                funcionOnConfirm();
            }
        });

    }

    lanzarModalConfirmarRevocar(_titulo: string, _mensaje: string, funcionOnConfirm: () => void) {

        Swal.close();

        Swal.fire({
            imageUrl: 'assets/img/icon/Confirmación.svg',
            imageWidth: 90,
            imageHeight: 90,
            imageAlt: 'Revocar',
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#AEAEAE',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-error mb-5',
                image: 'text-danger'
            }
        }).then((result) => {
            if (result.value) {

                funcionOnConfirm();
            }
        });

    }

    lanzarModalConfirmar(_titulo: string, _mensaje: string, funcionOnConfirm: () => void) {

        Swal.close();

        Swal.fire({
            imageUrl: 'assets/img/icon/icono-info.png',
            imageWidth: 90,
            imageHeight: 90,
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#00556a',
            cancelButtonColor: '#AEAEAE',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-confirm mb-5',
                image: 'text-danger',
                icon: 'texto-azul'
            }
        }).then((result) => {
            if (result.value) {

                funcionOnConfirm();
            }
        });

    }

    // 
    lanzarModalAnular(_titulo: string, _mensaje: string, funcionOnConfirm: () => void) {

        Swal.close();

        Swal.fire({
            imageUrl: 'assets/img/icon/Alerta_modal.png',
            imageWidth: 90,
            imageHeight: 90,
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#F0AD4E',
            cancelButtonColor: '#AEAEAE',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-anular mb-5',
                image: 'text-danger',
                icon: 'texto-azul'
            }
        }).then((result) => {
            if (result.value) {

                funcionOnConfirm();
            }
        });

    }

    // Cursos icono rojo
    lanzarModalConfirmarRojo(_titulo: string, _mensaje: string, funcionOnConfirm: () => void) {

        Swal.close();

        Swal.fire({
            imageUrl: 'assets/img/icon/icono-rojo.png',
            imageWidth: 90,
            imageHeight: 90,
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#AEAEAE',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-error mb-5',
                image: 'text-danger',
                icon: 'texto-azul'
            }
        }).then((result) => {
            if (result.value) {

                funcionOnConfirm();
            }
        });

    }

    lanzarModalConfirmarMateriales(_titulo: string, _mensaje: string, funcionOnConfirm: () => void) {

        Swal.close();

        Swal.fire({
            icon: 'warning',
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#AEAEAE',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-error mb-5',
                image: 'text-danger',
            }
        }).then((result) => {
            if (result.value) {

                funcionOnConfirm();
            }
        });

    }

    // * Modal Implementado para mensajes que no son de error 
    lanzarModalAceptar(_titulo: string, _mensaje: string, funcionOnClose: () => void) {

        Swal.close();

        Swal.fire({
            position: 'center',
            imageUrl: 'assets/img/icon/icono-info.png',
            imageWidth: 90,
            imageHeight: 90,
            title: _titulo,
            text: _mensaje,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonColor: '#00556a',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
                title: 'titulo-modal-confirm mb-5',
                image: 'text-danger',
                icon: 'texto-azul'
            },
            onClose: () => {

                funcionOnClose();
            }
        });

    }

}