import { Injectable, EventEmitter } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class ComunicacionComponentesService {

  // Observadores de material del tema
  materialTema$ = new BehaviorSubject<any>('');
  contenidoMaterial$ = new Subject<any>();

  // Observadores informacion del juego
  infoJuego$ = new BehaviorSubject<any>('');

  // Activa validaciones en los componenctes de adjuntar materiales
  mostrarValidacion$ = new Subject<any>();

  // SUBJECT PARA EL GUARDADO Y ACTUALIZACION DE TEMAS EN REGISTRO DE CURSO PASO 3
  formTemaSubir$ = new Subject<any>();
  formTemaActualizar$ = new Subject<any>();

  actualizarListadoTemas$ = new Subject<any>();

  cargandoMaterialVisualizador$ = new BehaviorSubject<boolean>(false);

  constructor() { }
}
